<template>
  <article class="badge" @click="$router.push(path)">
    <div class="badge--content">
      <component :is="`h${headingLevel}`" class="title"
        >{{ $brand.name }} ist ausgezeichnet!</component
      >
      <p>Unsere Testsiegel</p>
      <div v-if="$brand.isFonic()" class="awards">
        <img
          alt="Connect Award Netztest: Sie telefonieren weiter in eimem sehr guten Netz - FONIC ist wieder vorne mit dabei!"
          :src="connectBild"
          class="awards__image"
          height="220"
          width="290"
          loading="lazy"
        />
        <img
          alt="Focus Money Fairster Mobilfunkanbieter und Connect Award Netztest sehr gut"
          :src="focusMoney"
          class="awards__image awards__image--focus-money"
          height="240"
          width="310"
          loading="lazy"
        />
      </div>
      <div v-else class="awards">
        <img
          alt="FONIC mobile's Konditionen für Smartphone-Nutzer „sehr gut“ bewertet durch Deutsches Institut für Service-Qualität."
          :src="smartphoneAward"
          class="awards__image awards__image--fm"
          loading="lazy"
        />
        <img
          alt="FONIC mobile ist Premium Mobilfunkanbieter für Tablet-Intensivenutzer laut Bewertung des Deutsches Institut für Service-Qualität."
          :src="tabletAward"
          class="awards__image awards__image--fm"
          loading="lazy"
        />
      </div>
    </div>
    <ButtonComponent
      :clickhandler="() => $router.push(path)"
      :href="$router.resolve(path).href"
      class="badge--button"
      @click.stop
      >Zu den Testurteilen</ButtonComponent
    >
  </article>
</template>

<script>
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import connectBild from './resources/connect-siegel-2021.webp'
import focusMoney from './resources/fairster-mobilfunkanbieter-2024.webp'

import smartphoneAward from './resources/testsieger-konditionen-smartphone-fonic-mobile.webp'
import tabletAward from './resources/testsieger-konditionen-tablet-fonic-mobile.webp'
export default {
  name: 'AwardsBadge',
  components: {
    ButtonComponent
  },
  props: {
    headingLevel: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      path: { name: 'PortalAbout' },
      connectBild,
      focusMoney,
      smartphoneAward,
      tabletAward
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../badges';

.awards {
  display: flex;
  align-items: center;
  max-height: 350px;
  justify-content: center;
  position: relative;
}

.awards__link {
  flex-shrink: 1;
  padding-right: 10px;
}

.awards__image {
  align-self: center;
  max-width: 100%;
  object-fit: contain;
  padding: 10px 0;

  &.awards__image--fm {
    max-width: 37%;
    padding: 0 5px;
  }

  &--overlayed {
    position: absolute;
    padding: 0;
    object-fit: scale-down;
    height: 95%;
  }

  &--focus-money {
    padding: 20px 0;
  }
}
</style>
